import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import { useMemberstack } from "@memberstack/react";
import { useAuth } from '@memberstack/react'
import axios from "axios"

export default function Edition() {
  const memberstack = useMemberstack();
  const { gatedID } = useParams<{ gatedID: string }>()
  const [gatedList, setGatedList] = useState<any>()
  const [isFirstPage, setFirstPage] = useState(true)
  const { isLoggedIn } = useAuth();
  const IMAGE = process.env.REACT_APP_IMAGE

  const navigation = useNavigate();
  const toSignUp = () => navigation('/account/signup')
  const toSignIn = () => navigation('/account/login')
  const toAccount = () => navigation('/account/dashboard')
  const toGated = () => navigation('/gated')
  const toViewmemo = () => navigation("/home")


  /**
   * GET timestamp published memos in that day when execute home page.
   * SET|Array ...['10AM', '3PM', '8PM']
   */
  const getGatedList = useCallback(async () => {
    try {
      let list: any = await axios.get(`${process.env.REACT_APP_EDITION}/get_gated_list/${gatedID}`)
      setGatedList(list.data.data)
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => { getGatedList() }, [getGatedList])


  const createMarkup = (e: string) => {
    return { __html: e };
  }

  return (
    <div className="w-full h-full flex flex-col md:pt-[70px] px-[35px] sm:px-[33px] md:py-[50px] pt-[15px] pb-[30px] md:text-[12px] text-[16px]">
      <div className="flex-1 flex flex-col max-[479px]:justify-start md:justify-start justify-center gap-y-[30px] h-[90%]">
        <div className="block md:hidden">
          <div className="hidden xs:block">
            <div className="flex-1 flex flex-col gap-y-[20px] md:text-[14px] text-[16px]">
              <span className="text-center">Y IS PRESENTED IN MODE</span>
            </div>
          </div>
        </div>
        <div className='md:block max-[500px]:block w-full block xs:hidden md:text-[12px] text-[16px] h-[100%]'>
          {
            gatedList &&
            <div className="flex-1 flex mt-[5px] flex-col h-[100%] max-[310px]:h-[89%] max-[205px]:h-[86%] max-[185px]:h-[80%] gap-y-[22px] mainContent">
              <div className="flex flex-col items-start">
                <span className="cursor-pointer width-[100%] break-words whitespace-pre-line  mb-[30px]">{gatedList.title && gatedList.title}</span>
                <div className={`flex flex-col gap-y-[20px] "h-full mt-[30px]"} transition-all duration-500 w-[100%] overflow-hidden`}>
                  {
                    gatedList.media &&
                    <div className="w-full overflow-hidden rounded-xl">
                      <img width={'100%'} src={`${IMAGE}/gated/${gatedList.media}`} alt="" />
                    </div>
                  }

                  <span className="md:text-[12px] text-[16px] mt-[2px] text-gray-500 width-[100%] break-words whitespace-pre-line mb-1">{gatedList.media_title && gatedList.media_title}</span>

                  <div dangerouslySetInnerHTML={createMarkup(gatedList.memo_content)} className="break-words whitespace-pre-line flex flex-col gap-y-[20px]">

                  </div>
                </div>
              </div>
            </div>
          }

        </div>
        <div>
        </div>
      </div>
      <div className='md:block max-[500px]:block w-full block xs:hidden md:text-[12px] text-[16px] relative  ]'>
        {
          isFirstPage && isLoggedIn ?
            <div className="block mt-[-5px]">
              <div className="flex-1 flex flex-col gap-x-[20px]">
                <span onClick={toAccount} className="cursor-pointer">Account</span>
                <span onClick={toViewmemo} className="block cursor-pointer">View memos</span>
                <span onClick={toGated} className="block cursor-pointer">Go back</span>
              </div>
            </div> :
            isFirstPage && !isLoggedIn
              ? <div className="block">
                <div className="flex-1 flex flex-col gap-x-[20px] mt-[-5px]">
                  <span onClick={toSignUp} className="cursor-pointer">Sign up</span>
                  <span onClick={toSignIn} className="block cursor-pointer">Log in</span>
                </div>
              </div> : <></>
        }
      </div>
    </div>
  )
}

