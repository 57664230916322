import { useCallback, useEffect, useState } from "react";
import { getDateFormat1 } from "../utils";
import { useNavigate, useParams } from "react-router-dom";
import { useMemberstack } from "@memberstack/react";
import { useAuth } from "@memberstack/react";
import axios from "axios";
import heart from "../assets/images/heart.svg";
import heartO from "../assets/images/heart-o.svg";

export default function Edition() {
  const memberstack = useMemberstack();
  const { timestamp } = useParams<{ timestamp: string }>();

  const [isShow, setShow] = useState(false);
  const [showType, setShowType] = useState(0);
  const [showList, setShowList] = useState<boolean[]>();
  const [isButtonsShow, setButtonsShow] = useState(false);
  const [isAllOpen, setAllOpen] = useState(false);
  const [Data, setData] = useState<any[]>([]);
  const [addList, setAddList] = useState<any[]>([]);
  const [isFirstPage, setFirstPage] = useState(true);
  const [msg, setMsg] = useState("");
  const [timeList, setTimeList] = useState<any[]>([]);
  const { isLoggedIn } = useAuth();

  const BASEURL = process.env.REACT_APP_BASE_URL;
  const IMAGE = process.env.REACT_APP_IMAGE;

  const navigation = useNavigate();
  const toSignUp = () => navigation("/account/signup");
  const toSignIn = () => navigation("/account/login");
  const toAccount = () => navigation("/account/dashboard");
  const toArchive = () => navigation("/archive");
  const toLogout = () => (
    localStorage.setItem("email", ""), memberstack.logout(), navigation(`/home`)
  );

  let timeStamp = timestamp;

  //SET date to get memos published in that day
  let date = getDateFormat1().replaceAll("  ", "-");
  if (timeStamp && timeStamp.split("-") && timeStamp.split("-").length === 4) {
    let param = timeStamp.split("-");
    date = param[0] + "-" + param[1] + "-" + param[2];
  }

  const [Date, setDate] = useState(date.replaceAll("-", " "));

  useEffect(() => {
    getTimeList();
    console.log(isFirstPage)
  }, []);

  useEffect(() => {
    if (Data && Data.length) {
      setAllOpen(false);
      let list: boolean[] = [];
      setMsg("");
      setAddList([]);
      Data.map((item) => {
        list.push(false);
        addList.push("0");
      }); //FORMAT stories
      setShowList(list);
      setAddList([...addList]);
    }
  }, [Data, showType]);

  useEffect(() => {
    if (
      timeStamp &&
      timeStamp.split("-") &&
      timeStamp.split("-").length === 4
    ) {
      let param: any = timeStamp.split("-");
      let time: string = param[3];
      setTimeList([time]);
      getList(time);
    }
  }, [timeStamp]);

  /**
   * GET timestamp published memos in that day when execute home page.
   * SET|Array ...['10AM', '3PM', '8PM']
   */
  const getTimeList = useCallback(async () => {
    try {
      setTimeList([]);
      if (!timeStamp) {
        let list: any = await axios.get(
          `${process.env.REACT_APP_EDITION}/get_time_list/${date}`
        );
        if (list && list.status === 200 && list.data && list.data.date) {
          date = list.data.date;
          setDate(date.replaceAll("-", " "));
          if (list.data.timeStamp && list.data.timeStamp.length) {
            let times = list.data.timeStamp;
            times.sort();
            
            setTimeList([...times]);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  /**
   * GET published memo at time in that day
   * @param timeStamp: string 10AM, 3PM, 8PM
   */
  const getList = useCallback(async (time_stamp: string) => {
    try {
      setData([]);
      setMsg("");
      setAddList([]);
      let time = time_stamp;

      if (
        timeStamp &&
        timeStamp.split("-") &&
        timeStamp.split("-").length === 4
      ) {
        setButtonsShow(true);
        setShow(true);
      }
      if (time_stamp === "date") {
        setFirstPage(true);
        date = getDateFormat1().replaceAll("  ", "-");
        timeStamp = "";
        getTimeList();
        navigation("/home");
        return;
      }
      setFirstPage(false);
      // let response: any = await axios.get(`${BASEURL}/edition/staging/${date}-${time}`)
      let response: any = await axios.get(
        `${process.env.REACT_APP_EDITION}/${date}-${time}`
      );
      navigation(`/edition/${date}-${time}`);

      if (response && response.data && response.data.length)
        await setData([...response.data]);
    } catch (err) {
      return;
    }
  }, []);

  /**
   * INCREASE or DECREASE the button counter
   * @param id |string id of story saved in database
   * @param key |number indicate a story to increase or decrease
   */
  const updateCounter = async (id: string, key: number) => {
    try {
      let addStatus = localStorage.getItem(id);
      let response = await fetch(`${BASEURL}/edition/update_counter`, {
        method: "POST", // or 'GET' depending on your server endpoint
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id,
          add: !addStatus || addStatus === "origin" ? true : false,
        }),
      });
      if (response.status === 200) {
        if (!addStatus) {
          localStorage.setItem(id, "added");
          addList[key] = "2";
        } else if (addStatus === "origin") {
          localStorage.setItem(id, "added");
          if (addList[key] === "3" || addList[key] === "5") {
            addList[key] = "4";
          } else addList[key] = "2";
        } else if (addStatus === "added") {
          localStorage.setItem(id, "origin");
          if (addList[key] === "0") {
            addList[key] = "3";
          } else if (addList["4"] && addList[key] !== "2") {
            addList[key] = "5";
          } else addList[key] = "1";
        }
        setAddList([...addList]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * SHOW or HIDE a story in story list
   * @param key |number indicate a story to show or hide
   */
  const updateShowList = (key: number) => {
    if (showList) {
      let list = showList;
      list[key] = !list[key];
      setShowList([...list]);
    }
  };

  /**
   * EXPAND all stories (show title, media, media_title, sotry source, media_content all stories)
   */
  const expandAll = () => {
    let list: boolean[] = [];
    let i = 0;
    Data.map((item) => list.push(true));
    setShowList(list);
    setAllOpen(true);
  };

  /**
   * DISPLAY only titles of all stories
   */
  const simplifyAll = () => {
    let list: boolean[] = [];
    Data.map((item) => {
      list.push(false);
    });
    setShowList(list);
    setAllOpen(false);
  };

  const createMarkup = (e: string) => {
    return { __html: e };
  };

  return (
    <div className="w-full h-full flex flex-col md:pt-[70px] px-[35px] sm:px-[33px] md:py-[50px] pt-[15px] pb-[30px] md:text-[12px] text-[16px]">
      <div className="flex-1 flex flex-col h-full max-[479px]:justify-start md:justify-start justify-center gap-y-[30px]">
        <div className="block md:hidden">
          <div className="hidden xs:block">
            <div className="flex-1 flex flex-col gap-y-[20px] md:text-[14px] text-[16px]">
              <span className="text-center">Y IS PRESENTED IN MODE</span>
            </div>
          </div>
        </div>
        <div className="md:block h-full max-[500px]:block w-full block xs:hidden md:text-[12px] text-[16px]">
          <div className="flex flex-row h-[7%] max-[310px]:h-[11%] max-[205px]:h-[14%] max-[185px]:h-[18%] flex-wrap items-start gap-x-[10px]">
            {/* <span onClick={() => { getList('date'); setButtonsShow(false); setTimeout(() => { setShowType(0); setShow(false); simplifyAll(); }, 500) }} className="cursor-pointer">{date.replaceAll('-', '   ')}</span> */}
            <span
              onClick={() => {
                getList("date");
                setButtonsShow(false);
                setTimeout(() => {
                  setShowType(0);
                  setShow(false);
                  simplifyAll();
                }, 500);
              }}
              className="cursor-pointer mr-1"
            >
              {Date}
            </span>
            {timeList && timeList.length
              ? timeList.map((time: any, key: any) => {
                  return (
                    <span
                      key={key}
                      onClick={() => {
                        getList(time);
                        setShow(true);
                        setButtonsShow(true);
                        setShowType(key);
                      }}
                      className={`${
                        showType !== key && isShow && timeList.length !== 1
                          ? "hidden"
                          : "block"
                      }  cursor-pointer max-[180px]:w-full`}
                    >
                      {time}
                    </span>
                  );
                })
              : ""}
            {isAllOpen ? (
              <span
                onClick={() => {
                  simplifyAll();
                }}
                className={`${
                  isButtonsShow && isShow
                    ? "opacity-100 cursor-pointer"
                    : "opacity-0"
                } duration-500`}
              >
                Simplify
              </span>
            ) : (
              <span
                onClick={() => {
                  expandAll();
                }}
                className={`${
                  isButtonsShow && isShow
                    ? "opacity-100 cursor-pointer"
                    : "opacity-0"
                } duration-500`}
              >
                Expand all
              </span>
            )}
            {isLoggedIn && <span
              onClick={() => {
                navigation("/archive");
              }}
              className={`${
                isButtonsShow && isShow
                  ? "opacity-100 cursor-pointer"
                  : "opacity-0"
              } duration-500`}
            >
              Archive
            </span>}
          </div>
          <div className="flex-1 flex mt-[5px] flex-col h-[93%] max-[310px]:h-[89%] max-[205px]:h-[86%] max-[185px]:h-[80%] gap-y-[22px] mainContent">
            {Data.map((item, key) => (
              <div key={key} className="flex flex-col items-start">
                <span
                  className="cursor-pointer width-[100%] break-words whitespace-pre-line"
                  onClick={() => {
                    updateShowList(key);
                  }}
                >
                  {item.title && item.title}
                </span>
                <div
                  className={`flex flex-col gap-y-[20px] ${
                    showList && showList[key] ? "h-full mt-[30px]" : "h-0"
                  } transition-all duration-500 w-[100%] overflow-hidden`}
                >
                  {item.media && timeStamp && (
                    <div className="w-full overflow-hidden rounded-xl">
                      {/* <img width={'100%'} src={item.media ? IMAGE + "media/" + item.media.split('-')[1] + "-" + item.media.split('-')[2] + "-" + item.media.split('-')[3] + "/" + item.media.split('-')[4] + "/" + item.media : ''} alt="" /> */}
                      <img
                        width={"100%"}
                        src={
                          item.media
                            ? IMAGE +
                              "/media/" +
                              date +
                              "/" +
                              timeStamp.split("-")[3] +
                              "/" +
                              item.media
                            : ""
                        }
                        alt=""
                      />
                    </div>
                  )}
                  {item.media_text && (
                    <div
                      dangerouslySetInnerHTML={createMarkup(item.media_text)}
                      className="break-words whitespace-pre-line flex flex-col gap-y-[20px]"
                    ></div>
                  )}
                  <span className="text-[16px] md:text-[12px] mt-[2px] text-gray-500 width-[100%] break-words whitespace-pre-line">
                    {item.media_title && item.media_title}
                  </span>

                  <div
                    dangerouslySetInnerHTML={createMarkup(item.memo_content)}
                    className="break-words whitespace-pre-line flex flex-col gap-y-[20px]"
                  >
                  </div>
                  <div className="flex flex-col gap-y-[20px]">
                    <a
                      href={item.source ? "https://" + item.source : ""}
                      target="_blank"
                      className="text-[16px] md:text-[12px]"
                    >
                      Source
                    </a>
                    {(addList &&
                      addList.length &&
                      addList[key] === "0" &&
                      localStorage.getItem(item._id) === "added") ||
                    addList[key] === "4" ? (
                      <div
                        onClick={() => {
                          updateCounter(item._id, key);
                        }}
                        className="cursor-pointer"
                      >
                        <img width={"14px"} src={heart} alt="heart" />
                        &nbsp;&nbsp;&nbsp;
                        <span className="relative ml-[5%] top-[-17px]">
                          {item.counter}
                        </span>
                      </div>
                    ) : 
                    addList[key] === "2" ? (
                      <div
                        onClick={() => {
                          updateCounter(item._id, key);
                        }}
                        className="cursor-pointer flex items-center justify-start gap-x-[5px]"
                      >
                        <img width={"14px"} src={heart} alt="heart" />
                        &nbsp;&nbsp;&nbsp;
                        <span className="relative">
                          {item.counter + 1}
                        </span>
                      </div>
                    ) : addList[key] === "3" || addList[key] === "5" ? (
                      <div
                        onClick={() => {
                          updateCounter(item._id, key);
                        }}
                        className="cursor-pointer flex items-center justify-start gap-x-[5px]"
                      >
                        <img width={"14px"} src={heartO} alt="heartO" />
                        &nbsp;&nbsp;&nbsp;
                        <span className="relative">
                          {item.counter - 1}
                        </span>
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          updateCounter(item._id, key);
                        }}
                        className="cursor-pointer"
                      >
                        <img width={"14px"} src={heartO} alt="heartO" />
                        &nbsp;&nbsp;&nbsp;
                        <span className="relative ml-[5%] top-[-17px]">
                          {item.counter}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <span>{msg && msg}</span>
        </div>
      </div>
      <div className="md:block max-[500px]:block w-full block xs:hidden text-[16px] md:text-[12px]">
        {isFirstPage && isLoggedIn ? (
          <div className="block mt-[-5px]">
            <div className="flex-1 flex flex-col gap-x-[20px]">
              <span onClick={toArchive} className="block cursor-pointer">
                Archive
              </span>
              <span onClick={toAccount} className="cursor-pointer">
                Account
              </span>
              <span onClick={toLogout} className="block cursor-pointer">
                Log out
              </span>
            </div>
          </div>
        ) : isFirstPage && !isLoggedIn ? (
          <div className="block">
            <div className="flex-1 flex flex-col gap-x-[20px] mt-[-5px]">
              <span onClick={toSignUp} className="cursor-pointer">
                Sign up
              </span>
              <span onClick={toSignIn} className="block cursor-pointer">
                Log in
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
