import { useCallback, useEffect, useState } from "react";
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { useMemberstack, useAuth } from '@memberstack/react'

const Gated = () => {
    const memberstack = useMemberstack();
    const { getToken } = useAuth();
    const [archiveList, setArchiveList] = useState<any[]>([])
    const navigation = useNavigate()

    const getGatedList = useCallback(async () => {
        try {
            let list: any = await axios.get(`${process.env.REACT_APP_EDITION}/get_gated_list`)
            if (list.status !== 200) return

            if (list && list.data.data && list.data.data.length) {
                setArchiveList([...list.data.data])
            }
            else setArchiveList([])
        } catch (err) {
            console.log(err)
        }
    }, [])

    useEffect(() => {
        if (!getToken()) navigation('/account/login')
        else getGatedList()
    }, [getGatedList])

    return (
        <div className="w-full h-full flex flex-col px-[35px] sm:px-[33px] md:pt-[70px] pt-[45px] md:py-[50px] pb-[30px] md:text-[12px] text-[16px] max-[479px]:justify-start md:justify-start justify-center">
            <div className="block md:hidden">
                <div className="hidden xs:block">
                    <div className="flex-1 flex flex-col gap-y-[20px] md:text-[14px] text-[16px]">
                        <span className="text-center">Y IS PRESENTED IN MODE</span>
                    </div>
                </div>
            </div>
            <div className="md:block md:h-[93%] h-[92%] max-[500px]:block w-full block xs:hidden md:text-[12px] text-[16px]">
                <div className="flex-1 flex h-full flex-col justify-start gap-y-[26px] mainContent">
                    {
                        archiveList && archiveList.length
                            ? archiveList.map((list, key) => (
                                <div key={key} onClick={() => navigation(`/viewgated/${list._id}`)} className="flex flex-row flex-wrap items-start gap-x-[10px]">
                                    <div className="cursor-pointer mr-1">
                                        <span>{list.title}</span>
                                    </div>
                                </div>
                            )) : <></>
                    }
                </div>
            </div>
            <div className="md:block h-[7%] max-[500px]:block w-full block xs:hidden md:text-[12px] text-[16px]">
                <div className="flex mt-[18px] flex-row items-center mr-20 gap-x-[20px]">
                    <span onClick={() => navigation('/home')} className="cursor-pointer">Exit gated</span>
                </div>
            </div>
        </div>
    )
}

export default Gated