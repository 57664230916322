import { useEffect } from 'react';
import Router from './router';

export default function App() {

    const handleResize = () => {
        let parent: HTMLElement = document.getElementById("parent")!;
        if (window.innerWidth < 768) {
            parent.style.height = `${window.innerHeight}px`;
        }
    }

    useEffect(() => {
        let parent: HTMLElement = document.getElementById("parent")!;
        if (window.innerWidth < 768) {
            parent.style.height = `${window.innerHeight}px`;
        }
        window.addEventListener('resize', handleResize)
    })

    return (
        <div id="parent" className="md:h-screen w-full flex flex-col items-center justify-center md:text-[12px] text-[16px]">
            <div className="md:bg-phone w-full md:w-[300px] h-full md:h-[630px] md:bg-[length:300px_630px] relative">
                <Router />
                <div className="absolute hidden md:block bottom-[-38px] text-center w-full md:text-[14px] text-[16px]">DESIGNED FOR MOBILE</div>
            </div>
        </div>
    )
}